var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import RepairDataImport from "~/components/data-repair/repair-data-import.vue";
import AddCountryData from "~/components/data-repair/country-data/add-country-data.vue";
import EditCountryData from "~/components/data-repair/country-data/edit-country-data.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { VillageCommitteeDataService } from "~/services/repair-service/village-committee-data.service";
import CountryDetail from "~/components/data-repair/country-data/country-detail.vue";
import { DataRepairImportService } from "~/services/repair-service/data-repair-import.service";
import ImportBatchDeletion from "~/components/data-repair/census-data/import-batch-deletion.vue";
import { dataRepair } from "~/config/enum.config";
//@Auth(105)
var CountyData = /** @class */ (function (_super) {
    __extends(CountyData, _super);
    function CountyData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.imgUrls = [];
        _this.loading = {
            state: false
        };
        _this.rowData = {};
        _this.importType = "TOWN_RESOURCE"; //村委信息
        _this.editModel = {};
        _this.dataSet = [];
        _this.currentType = dataRepair;
        _this.kosekiModel = {
            address: "",
            name: "",
            idNo: "",
            kosekiArea: "",
            koseki: "",
            applyTransferArea: "",
            applyFileContent: ""
        };
        _this.data = {};
        _this.dialog = {
            detail: false,
            edit: false,
            import: false,
            certificate: false,
            deletion: false
        };
        return _this;
    }
    CountyData.prototype.created = function () { };
    CountyData.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * keep-alive生命周期钩子函数
     */
    CountyData.prototype.activated = function () {
        // 加载数据
        this.refreshData();
    };
    /**
     * 查询村委资料
     */
    CountyData.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.villageCommitteeDataService
            .search(this.kosekiModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    //添加村委资料弹窗
    CountyData.prototype.addClick = function () {
        this.dialog.detail = true;
    };
    CountyData.prototype.dataImpClick = function () {
        this.dialog.import = true;
    };
    /**
     * 查询村委详情
     */
    CountyData.prototype.viewDetails = function (row) {
        this.rowData = row;
        this.dialog.country = true;
    };
    /**
     * 删除村委资料
     */
    CountyData.prototype.deleteKoseki = function (scope) {
        var _this = this;
        this.$confirm("您确定要删除吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.loading.state = true;
            _this.villageCommitteeDataService
                .delete(scope.row, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshData();
            });
        }, function (err) {
            _this.$message.error(err.msg);
        })
            .catch(function () {
            _this.$message({
                type: "info",
                message: "已取消删除"
            });
        });
    };
    /**
     * 获取下载模板
     */
    CountyData.prototype.downLoadTemplate = function () {
        var _this = this;
        this.kosekiModel.applyFileContent = this.importType;
        this.dataRepairImportService
            .getSystemTemplate(this.kosekiModel, this.loading)
            .subscribe(function (data) {
            if (data) {
                _this.uploadFileService
                    .getFileStreamById(data.value)
                    .subscribe(function () { return _this.$message.success("下载成功"); });
            }
        }, function (_a) {
            var msg = _a.msg;
        });
        this.kosekiModel.applyFileContent = null;
    };
    /**
     * 修改村委资料弹框
     */
    CountyData.prototype.editKoseki = function (scope) {
        this.editModel = scope.row;
        this.dialog.edit = true;
    };
    // 查看附件
    CountyData.prototype.fileDetails = function (fileIds) {
        var _this = this;
        if (!fileIds)
            return;
        fileIds.forEach(function (v) {
            _this.uploadFileService.getFileUrlById(v).subscribe(function (data) {
                _this.imgUrls.push(data);
            });
        });
        this.dialog.certificate = true;
    };
    CountyData.prototype.importBatchDeletion = function () {
        this.dialog.deletion = true;
    };
    __decorate([
        Dependencies(PageService)
    ], CountyData.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CountyData.prototype, "sortService", void 0);
    __decorate([
        Dependencies(VillageCommitteeDataService)
    ], CountyData.prototype, "villageCommitteeDataService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], CountyData.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(DataRepairImportService)
    ], CountyData.prototype, "dataRepairImportService", void 0);
    CountyData = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                AddCountryData: AddCountryData,
                EditCountryData: EditCountryData,
                RepairDataImport: RepairDataImport,
                CountryDetail: CountryDetail,
                ImportBatchDeletion: ImportBatchDeletion
            }
        })
    ], CountyData);
    return CountyData;
}(Vue));
export default CountyData;
